/* eslint no-undef: "off" */
import { envUrls } from '@/shared/configs/rebrand_urls';

(() => {
  const initFormRecaptcha = (form) => {
    const button = form.querySelector('button');

    const elementsNotRequired = form.querySelectorAll('[required=false]');

    elementsNotRequired.forEach((elm) => {
      elm.removeAttribute('required');
    });

    button.onclick = () => {
      if (form.querySelector('.g-recaptcha-response').value && form.checkValidity()) {
        form.submit();
      }
    };

    grecaptcha.render(
      button,
      {
        sitekey: process.env.VUE_APP_FORM_INVISIBLE_RECAPTCHA_SITE_KEY,
        callback(token) {
          form.querySelector('.g-recaptcha-response').value = token;
          if (form.checkValidity()) form.submit();
        },
      },
    );
  };

  const ksendOnloadCallback = () => {
    document.querySelectorAll('form[klicksend-form-id]').forEach(initFormRecaptcha);
    document.querySelectorAll('form[klicksend-form-value]').forEach(initFormRecaptcha);
  };

  const addGRecaptchaScript = () => {
    const gRecaptchaUrl = 'www.google.com/recaptcha/api.js';
    const callback = 'ksendOnloadCallback';
    const src = `https://${gRecaptchaUrl}?onload=${callback}&render=explicit`;

    if (document.querySelector(`script[src="${src}"]`)) return false;

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;

    document.querySelector('head').appendChild(script);

    return true;
  };

  const fromKlickart = () => (window.location.host === envUrls.klickart_host);

  const init = () => {
    if (fromKlickart() || !addGRecaptchaScript()) return;
    window.ksendOnloadCallback = ksendOnloadCallback;
  };

  init();
})();
